import { UserManager } from "oidc-client-ts";

const FORCE_TOKEN_REFRESH = false && import.meta.env.DEV;

const override = {};
const checkStatus = function checkStatus(res) {
  if (res.ok) {
    return res;
  }
  throw res;
};

/**
 * Maps an access token to a promise that is resolved when the token is refreshed
 * This is to prevent multiple requests from trying to refresh the token at the same time
 */
const refreshPromises = {};

class DefaultProvider {
  constructor(options) {
    this.options = options;
    this.mgr = new UserManager({
      client_id: options.client_id,
      authority: options.endpoint,
      redirect_uri: `${location.protocol}//${location.host}/authcallback`,
      response_type: "code",
    });
  }

  handler(type, params) {
    let accessToken = sessionStorage.getItem("immi_access_token");
    switch (type) {
      case "signIn":
        return new Promise((resolve, reject) => {
          console.log("[OIDC] Redirecting to signin");
          this.mgr
            .signinRedirect()
            .then((response) => {
              // Tokens are returned via authCallback below
              return resolve({ authenticating: true });
            })
            .catch((error) => {
              console.log("Error redirecting for sign in");
              console.error(error);
              resolve({ authenticated: false });
            });
        });
      case "silentAuthCheck":
        if (!accessToken) {
          console.log("[OIDC] No token");
          return Promise.resolve({ authenticated: false });
        }

        if (refreshPromises[accessToken]) return refreshPromises[accessToken];

        return (refreshPromises[accessToken] = new Promise(async (resolve, reject) => {
          // access/refresh token might still be valid - we perform silent re-auth
          if (!FORCE_TOKEN_REFRESH && !this.tokenExpired(accessToken)) {
            let user = await this.mgr.getUser();
            await this.returnUser(resolve, reject, user);
          } else {
            // so we can validate the token is still valid from the server
            console.log("[OIDC] Silent refreshing token", this.mgr, accessToken);
            this.mgr
              .signinSilent({
                extraTokenParams: {
                  redirect_uri: this.mgr.settings.redirect_uri,
                  ...(this.mgr.settings.extraTokenParams || {}),
                },
              })
              .then(async (resp) => {
                if (resp) {
                  this.storeTokens(resp);
                  await this.returnUser(resolve, reject, resp);
                } else {
                  resolve({ authenticated: false });
                }
              })
              .catch((error) => {
                console.log("[OIDC] Failed to refresh token");
                console.error(error);
                // Something went wrong. Send back false and maybe they will get to sign in manually
                resolve({ authenticated: false });
              });
          }
        }).then((result) => {
          delete refreshPromises[accessToken];
          return result;
        }, (error) => {
          delete refreshPromises[accessToken];
          throw error;
        }));
      case "authCallback":
        return new Promise((resolve, reject) => {
          this.mgr
            .signinRedirectCallback()
            .then(async (resp) => {
              this.storeTokens(resp);
              await this.returnUser(resolve, reject, resp, true);
              //getUserFromIdToken(resolve, resp.id_token, resp.refresh_token, true);
            })
            .catch((err) => {
              console.log("Error in OIDC Provider", err);
              reject();
            });
        });
      case "validAuth":
        if (!accessToken || this.tokenExpired(accessToken)) return false;
        else return true;
      case "applyAuthentication":
        if (accessToken) {
          params[this.options.header_key || "Authorization"] = "Bearer " + accessToken;
        }
        return params;
      case "applySecureEndpoint":
        // TO REMOVE
        //return 'example';
        break;
      case "getUser":
        return this.user;
      case "getBackendUrl":
        if (!this.options.getBackendUrl) Promise.reject("Invalid configuration - no getBackendUrl provided in options");
        return this.options.getBackendUrl(this.user);
      case "signOut":
        console.log("[OIDC] Redirecting to signout");
        sessionStorage.removeItem("immi_access_token");
        this.mgr
          .signoutRedirect({
            post_logout_redirect_uri: `${location.protocol}//${location.host}/loggedOut`,
          })
          .then((resp) => {
            // In Azure this doesn't seem to come back, so removing above
            // Though this could cause a problem if there is an error
          });
        break;
      default:
        return Promise.reject(`Unsupported Imminently Platform authClient action type ${type}`);
    }
  }

  storeTokens(response) {
    sessionStorage.setItem("immi_access_token", this.options.b2c ? response.id_token : response.access_token);
  }

  tokenExpired(token) {
    const parts = token.split(".");
    const payload = JSON.parse(atob(parts[1]));
    const exp = payload.exp * 1000;
    //console.log("[OIDC] token expiry check:", new Date(exp));
    return new Date(exp) <= new Date();
  }

  async returnUser(resolve, reject, response, redirect = false) {
    try {
      const parts = response.id_token.split(".");
      const profile = JSON.parse(Buffer.from(parts[1], "base64"));
      let user = this.parseProfile(profile);
      console.log("PROF", profile);
      if (this.options.additionalInformationCheck) {
        // Call this URL to get additional information about the user
        let headers = {};
        if (this.options.customHeaders) headers = this.options.customHeaders(headers);

        headers[this.options.header_key || "Authorization"] = `Bearer ${
          this.options.b2c ? response.id_token : response.access_token
        }`;
        let additional = await fetch(this.options.additionalInformationCheck, {
          headers,
        })
          .then(checkStatus)
          .then((res) => res.json());
        user = { ...user, ...additional };
      }
      console.log("[OIDC] token expires at:", new Date(response.expires_at * 1000));
      // console.log('token expires at:', new Date(response.expires_at * 1000));
      this.user = user;
      if (this.options.onAfter) this.options.onAfter(this.user);
      resolve({
        authenticated: true,
        user: user,
        redirectTo: redirect ? this.options.authenticatedRedirectPath : null,
      });
    } catch (error) {
      //console.log("error getting profile", error);
      reject(error);
    }
  }

  parseProfile(profile) {
    console.log("pro", profile);
    return {
      username: profile["user_displayname"],
      id: profile["user_id"],
      roles: ["user", "admin"],
    };
  }
}
const DefaultIdentityProvider = (options) => {
  let instance = new DefaultProvider(options);

  return instance.handler;
};

class AzureProvider extends DefaultProvider {
  constructor(options) {
    super(options);
    let authority = options.endpoint;
    if (!options.b2c && !authority.includes("/v2.0")) authority = `${authority}/v2.0`;

    this.mgr = new UserManager({
      client_id: options.client_id,
      authority: authority,
      redirect_uri: `${location.protocol}//${location.host}/authcallback`,
      response_type: "code",
      extraTokenParams: {
        scope: `profile openid ${options.scope} offline_access`,
      },
    });
  }

  parseProfile(profile) {
    return {
      username: profile.name,
      first_name: profile.given_name,
      last_name: profile.family_name,
      id: profile["oid"],
    };
  }
}

const AzureIdentityProvider = (options) => {
  let instance = new AzureProvider(options);
  return Object.assign((type, params) => {
    return instance.handler(type, params);
  }, {
    instance
  });
  //return instance.handler;
};

export { AzureIdentityProvider, DefaultIdentityProvider };
