import { useStore } from "react-redux";
import { showModal } from "@imminently/imminently_platform";
import { Box, TextField } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { global } from "./global";
import html2canvas from "html2canvas";
import { Button } from "@components";

export interface ErrorReportFormProps {
  onSubmit?: (description: string) => void;
}

const ErrorReportForm = (props: ErrorReportFormProps) => {
  const { onSubmit } = props;
  const [description, setDescription] = useState("");
  const { reportError } = useErrorReporting();
  const [submit, setSubmit] = useState(false);
  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      gridGap={"1rem"}
    >
      <TextField
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        multiline={true}
        minRows={6}
        maxRows={12}
        label={"Description"}
      />
      <Box
        justifyContent={"flex-end"}
        display={"flex"}
      >
        <Button
          disabled={!description || submit}
          variant={"contained"}
          color={"primary"}
          loading={submit}
          onClick={async () => {
            try {
              setSubmit(true);
              await reportError({ description });
              if (onSubmit) {
                onSubmit(description);
              }
            } catch (e) {
              console.error(e);
              setSubmit(false);
            }
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export interface ReportErrorOptions {
  error?: Error;
  description?: string;
}

export const useErrorReporting = () => {
  const store = import.meta.env.NODE_ENV === "test" ? ({} as any) : useStore();
  const dispatch = store.dispatch;
  return {
    open: () => {
      dispatch(
        showModal(
          {
            open: true,
            title: "Feedback",
          },
          ({ close }) => (
            <ErrorReportForm
              onSubmit={() => {
                close();
                toast.info("Thank you for your feedback!");
              }}
            />
          ),
        ),
      );
    },
    reportError: async (options?: ReportErrorOptions) => {
      let image: string | undefined = undefined;
      try {
        const canvas = await html2canvas(document.body.querySelector("#root") as any);
        image = canvas.toDataURL("image/png");
      } catch (error) {
        console.error(error);
      }

      return await global.client("auth/reportError", {
        method: "POST",
        body: {
          url: window.location.href,
          logs: LOGS,
          image: image,
          description: options?.description ?? options?.error?.stack,
          store: store.getState(),
        } as unknown as BodyInit,
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
  };
};

export interface ConsoleLog {
  args: any[];
  timestamp: number;
  level: ConsoleMethod;
}

const LOGS: ConsoleLog[] = [];
// @ts-ignore
window.__ERROR_REPORTING_LOGS = LOGS;

const CONSOLE_METHODS_TO_TRACK = ["error", "warn", "info", "log", "trace"] as const;
type ConsoleMethod = (typeof CONSOLE_METHODS_TO_TRACK)[number];

export const initErrorReporting = () => {
  if (import.meta.env.DEV) {
    return;
  }

  for (const method of CONSOLE_METHODS_TO_TRACK) {
    const original = console[method];
    console[method] = (...args: any[]) => {
      try {
        const res = JSON.stringify(args);
        // ensure the args are stringifiable, otherwise just log the original
        LOGS.push({ args, level: method, timestamp: Date.now() });
      } catch (e) {
        original.apply(console, args);
      }
      original.apply(console, args);
    };
  }
};
