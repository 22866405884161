import slice from "lodash/slice";
import { defaultI18nProvider, feathersProvider } from '@imminently/imminently_platform';
import authProvider from './authProvider';
import { config } from './config';

import enMessages from "./i18n.en.json";
import zhMessages from "./i18n.zh.json";

export const dataProvider = feathersProvider({
  host: config.BACKEND_URL,
  getUrlFromAuth: true,
  trailingResourceSlash: config.ENFORCE_URL_RESOURCE_SLASH === "true" ? true : false,
  customHeaders: (headers) => {
    console.log('DATA PROVIDER CUSTOM')
    headers["X-TENANCY"] = localStorage.getItem("decisively.tenant");
    return headers;
  },
});

export const i18nProvider = defaultI18nProvider(
  "en",
  {
    en: enMessages,
    "zh-Hans": zhMessages,
  },
  {
    allowMissing: true,
    onMissingKey: (t) => {
      if (typeof t === "object") {
        return t.toString();
      }

      // Remove the translation prefix
      const split = t.split(".");
      if (["options", "stage", "control", "screen"].includes(split[0])) return slice(split, 1).join(".");

      return t;
    },
  },
);

// re-export
export { authProvider };