import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { PlateProvider } from "@udecode/plate";
import { Box, FormControl, TextFieldProps, Typography } from "@material-ui/core";
import { DayPicker } from "react-day-picker";
import { FixedRichTextToolbar } from "@common/editor/RuleAuthor/toolbar/FixedToolbar";
import { PlateRichTextEditor, richTextPlugins } from "@common/editor/components/PlateRichTextEditor";
import { editableProps, statusOptions, priorityOptions } from "./taskUtils";
import { Button, Checkbox, ChipInput, InlineEdit, TextField } from "@components";
import { DropdownSelect, Popover, PopoverContentProps, PopoverTrigger, PopoverContent } from "@components/radix";
import { UserCombobox } from "@components/radix/UserSelect/UserSelect";
import styles from "./tasks.module.scss";
import { PriorityLabel, StatusLabel } from "./TaskComponents";
import { AssigneeGroup } from "@components/forms/FormikControls";
import { useTranslation } from "react-i18next";
import { CalendarIcon } from "@icons";
import styled from "styled-components";
import { Calendar } from "@components/radix/Calendar/Calendar";
import moment from "moment";

export const TaskDetail = ({ label, icon, children }: { label: string; icon?: ReactNode; } & PropsWithChildren) => {
  return (
    <Box className={styles.detail}>
      <Box style={{ flex: 2 }} className={styles.detail}>
        {icon}
        <Typography aria-label={label} style={{ flexShrink: 0 }}>{label}</Typography>
      </Box>
      <Box aria-labelledby={label} style={{ display: "flex", flex: 3 }}>
        {children}
      </Box>
    </Box >
  );
};

export const TitleField = () => {
  const { control, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="title"
      control={control}
      rules={{
        required: true
      }}
      render={({ field }) => (
        <Typography variant="h3" style={{ lineHeight: "2rem", border: errors.title ? '2px solid red' : '' }}>
          <InlineEdit className={styles.title} placeholder={`${t('tasks.title_placeholder')}...*`} value={field.value} onChange={field.onChange} />
        </Typography>

      )}
    />
  )
};


// const status = <Label className={styles.label} color={statusColor[task.status]}>{taskDisplayName}</Label>;
// const priority = <Label className={styles.label} color={priorityColor[task.priority]}>{task.priority}</Label>;

const statusSelectOptions = statusOptions.map(option => ({ ...option, label: <StatusLabel status={option.value} /> }));

export const StatusSelector = ({ omit = [] }: { omit?: string[] | ((val: string) => boolean) }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const items = statusSelectOptions.filter(val => Array.isArray(omit) ? !omit.includes(val.value) : omit(val.value));

  return (
    <Controller
      name="status"
      control={control}
      render={({ field }) => (
        <DropdownSelect items={items} value={field.value} onChange={field.onChange}>
          {
            field.value ? <StatusLabel status={field.value.value} /> : `${t('tasks.status_placeholder')}...`
          }
        </DropdownSelect>
      )}
    />
  );
};

export const AssigneeSelector = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="assigneesData"
      control={control}
      render={({ field }) => (
        <>
          <UserCombobox
            value={field.value}
            onChange={(value) => field.onChange(value)}
            searchProps={{ placeholder: t('search') }}
          >
            <Button size="small" type="tertiary" fullWidth style={{ justifyContent: "flex-start" }}>
              <AssigneeGroup users={field.value} max={4} />
            </Button>
          </UserCombobox>
        </>
      )}
    />
  );
};

const DatePicker = styled(DayPicker)`

`;

export const DateSelector = ({ label, popover = {} }: { label: string, popover?: PopoverContentProps }) => {
  const { control } = useFormContext();

  const target = document.getElementsByClassName('MuiDialog-container')[0] as HTMLElement ?? document.body;

  return (
    <Controller
      name="dueDate"
      control={control}
      render={({ field }) => (
        <Popover modal={true}>
          <PopoverTrigger asChild>
            <Button size="small" type="tertiary" fullWidth style={{ justifyContent: "flex-start" }}>
              {field.value ? moment(field.value).format("DD MMMM, YYYY") : label}
            </Button>
          </PopoverTrigger>
          <PopoverContent portal={{ container: target }} {...popover}>
            <Calendar
              mode="single"
              initialFocus
              selected={field.value}
              onSelect={(day) => {
                console.log('setting date', day);
                field.onChange(day);
              }}
            />
          </PopoverContent>
        </Popover>
      )}
    />
  );
};

const prioritySelectOptions = priorityOptions.map(option => ({ ...option, label: <PriorityLabel priority={option.value} /> }));

export const PrioritySelector = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name="priority"
      control={control}
      render={({ field }) => (
        <DropdownSelect items={prioritySelectOptions} value={field.value} onChange={field.onChange}>
          {
            field.value ? <PriorityLabel priority={field.value.value} /> : `${t('tasks.priority_placeholder')}...`
          }
        </DropdownSelect>
      )}
    />
  );
};

export const TagsInput = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="tags"
      control={control}
      render={({ field }) => (
        <ChipInput
          fullWidth
          value={field.value}
          onChange={field.onChange}
          blurBehavior='add'
        />
      )}
    />
  );
};

export const DescriptionInput = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="description"
      control={control}
      render={({ field }) => (
        <Box className={clsx(styles.textBox, styles.description)}>
          <PlateProvider id={"description-editor"} plugins={richTextPlugins} value={field.value} onChange={field.onChange}>
            <FixedRichTextToolbar className={styles.toolbar} />
            <PlateRichTextEditor id={"description-editor"} editableProps={editableProps({ placeholder: `${t('tasks.description_placeholder')}...` })} />
          </PlateProvider>
        </Box>
      )}
    />
  );
};

export const TextInput = ({ name, label, helperText, ...props }: { name: string, label?: string; helperText?: string; } & TextFieldProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          name={name}
          value={field.value}
          label={label}
          fullWidth
          helperText={(fieldState.isTouched && fieldState.error?.message) ?? helperText}
          error={fieldState.isTouched && Boolean(fieldState.error)}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...props}
        />
      )}
    />
  );
};

export const CheckboxInput = ({ name, label }: { name: string, label: string; }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <Checkbox
            checked={field.value}
            onChange={field.onChange}
            name={name}
            color="primary"
          />
          <Typography>{label}</Typography>
        </FormControl>
      )}
    />
  );
}